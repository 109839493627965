import { alpha, Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

const images = [
  require("../assets/webapp/webapp.png"),
  require("../assets/webapp/grid.png"),
  require("../assets/webapp/profitlogs.png"),
  require("../assets/webapp/web-app-signals.png"),
  require("../assets/webapp/webapp-indicators.png"),
  require("../assets/webapp/custom-backtesting.png"),
]

export default function Hero() {

  const [ imageIndex, setImageIndex ] = useState(0)


  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            component="h1"
            variant="h2"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
            }}
          >
            AlgoTrader&nbsp;
            <Typography
              component="span"
              variant="h2"
              sx={{
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
              Trading Bot
            </Typography>
          </Typography>
          <Typography variant="body1" textAlign="center" color="text.secondary">
          Elevate Your Trading Game with AlgoTrader: Precision, Performance, Profits.
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
          >
       
          </Stack>
          
        </Stack>

        <Tabs value={imageIndex} onChange={(_, index) => setImageIndex(index)} sx={{ mb: 1 }}>
        <Tab label="Dashboard" value={0} />
        <Tab label="Grid Bot" value={1} />
        <Tab label="Profit Analysis" value={2} />
        <Tab label="Signals" value={3} />
        <Tab label="Build your own strategy" value={4} />
        <Tab label="Custom Backtesting" value={5} />

        </Tabs>
        <img src={images[imageIndex]} style={styles.image} alt="webapp"/>

      </Container>
    </Box>
  );
}

const styles = {
  image: {
    alignSelf: 'center',
    width: '100%',
    borderRadius: 16,
  }
}